<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data' />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('c_management.all_customer') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <datatable-dropdown />
            </div>
          </div>
          <div class="card-body">
            <Datatable ref="datatable" :url="datatableUrl" :dataUrl="datatableDataUrl" @data="loadDatatableData($event)">
              <!-- <template v-slot:head>
                      Table head özelleştirme
                  </template> -->
              <template v-slot:body>
                <tr v-for="(item, index) in datatableData" :key="'customer-' + index">
                  <th scope="row">{{ item.id }}</th>
                  <td>
                    <router-link :to="`/users/${item.id}/overview`" class="text-primary font-weight-bold">
                      {{ item.name }}
                    </router-link>
                  </td>
                  <td>{{ $t(`service.${item.type}`) }}</td>
                  <td>{{ $t(`service.${item.level}`) }}</td>
                  <td>{{ $t(`service.${item.status}`) }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>{{ item.referral_employee_code }}</td>
                  <!-- <td>{{ item.last_activity_at }}</td> -->

                  <td v-if="item.kyc_verification === null">Pending</td>
                  <td v-else-if="item.kyc_verification === 1">Pending</td>
                  <td v-else-if="item.kyc_verification === 2">Pending</td>
                  <td v-else-if="item.kyc_verification === 3">In-Process</td>
                  <td v-else-if="item.kyc_verification === 4">Error in kyc</td>
                  <td v-else-if="item.kyc_verification === 5">Completed</td>
                  <td v-else-if="item.kyc_verification === 6">In-Review</td>
                  <td v-else>--------</td>

                  <td>
                    <div class="d-flex justify-content-end flex-shrink-0">
                      <button class="btn btn-primary " v-if="item?.kyc_verification == 6"
                        @click="acceptKyc(item)">Accept</button>
                      &nbsp;
                      <button class="btn btn-danger" v-if="item?.kyc_verification == 6"
                        @click="rejectKyc(item)">Reject</button>
                    </div>
                  </td>

                  <td style="display:none;">{{ item.email }}</td>
                  <td style="display:none;">{{ item.phone }}</td>
                  <td>
                    <div class="d-flex justify-content-end flex-shrink-0">
                      <button @click="sendVerificationEmail(item.id)" v-if="checkPermission('crm.users.show')"
                        class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1" :title="$t('c_management.send_verification_email')">
                        <i class="la la-envelope text-primary"></i>
                      </button>
                      <button @click="goToUserDetails(item.id)" v-if="checkPermission('crm.users.show')"
                        class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1" :title="$t('commons.details')">
                        <i class="la la-eye"></i>
                      </button>
                      <button @click="showPastTransactionsModal(item.id)" v-if="checkPermission('crm.users.show.history')"
                        class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1"
                        :title="$t('employees.past_transactions')">
                        <i class="la la-history"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </template>
            </Datatable>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
    <AuditModal v-if="auditModalVisible" ref="auditModal" :close="() => $store.commit('users/TOGGLE_AUDIT_MODAL', false)">
      <template v-slot:header>
        <h1>{{ $t('employees.audit_modal_title') }}</h1>
      </template>
      <template v-slot:body>
        <table class="table table-bordered collapsed table-head-bg" style="width:100%">
          <thead>
            <tr>
              <th scope="col">{{ $t('employees.audit_user') }}</th>
              <th scope="col">{{ $t('employees.audit_area') }}</th>
              <th scope="col">{{ $t('employees.audit_old_value') }}</th>
              <th scope="col">{{ $t('employees.audit_new_value') }}</th>
              <th scope="col">{{ $t('employees.audit_date') }}</th>
            </tr>
          </thead>
          <tbody v-for="(auditItem, index) in audits" :key="'audit-' + index">
            <tr v-for="(newValKey, newValKeyIndex) in Object.keys(auditItem.new_values)"
              :key="'newValKeyIndex-' + newValKeyIndex">
              <td scope="row"><span class="truncateText">
                  <template v-if="auditItem.user">
                    <a href="javascript:;" @click="goToUserDetails(auditItem.user.id, true)">
                      {{ auditItem.user['name'] }} {{ auditItem.user['surname'] }}
                    </a>
                  </template>
                </span>
              </td>
              <td scope="row"><span class="truncateText">{{ newValKey }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.old_values[newValKey] || '' }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.new_values[newValKey] || '' }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.updated_at }}</span></td>
            </tr>
          </tbody>
        </table>
      </template>
    </AuditModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import DatatableDropdown from '@/components/datatable/Dropdown.vue'
import 'bootstrap-daterangepicker';
import AuditModal from '@/components/custom/AuditModal.vue';
import Datatable from "@/components/datatable/Datatable.vue";
import api_old from "@/api/index_old";
import $ from "jquery";
import Swal from 'sweetalert2'

export default {
  name: 'AllCustomer',
  data() {
    return {
      isLoading: true,
      datatableUrl: "",
      datatableDataUrl: "",
      datatableData: [],
    }
  },
  components: {
    Breadcrumb,
    DatatableDropdown,
    AuditModal,
    Datatable,
  },
  computed: {
    ...mapState({
      auditModalVisible: state => state.users.auditModalVisible,
      audits: state => state.users.audits,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang
    }),
    breadcrumb_data() {
      return {
        title: this.$t('c_management.title'),
        subtitle: [
          this.$t('c_management.all_customer')
        ]
      }
    }
  },
  methods: {
    ...mapActions('customer', ['ACCPET_KYC_STATUS', 'REJECT_KYC_STATUS']),
    loadDatatableData(data) {
      this.$set(this, "datatableData", data);
    },
    acceptKyc(item) {
      this.ACCPET_KYC_STATUS({ id: item.id, status: 5 })
    },
    rejectKyc(item) {
      this.REJECT_KYC_STATUS({ id: item.id, status: 1 })
    },
    checkPermission(permission) {
      return this.permissions?.some(val => val.name == permission);
    },
    getTimeForParsedDate(rawDate) {
      return new Date(rawDate).getTime();
    },
    goToUserDetails(user_id, closeAuditModal = false) {
      if (closeAuditModal) {
        this.$store.commit('users/TOGGLE_AUDIT_MODAL', false);
      }
      this.$router.push({ path: `/users/${user_id}` })
    },
    showPastTransactionsModal(user_id) {
      this.$store.dispatch('users/GET_CUSTOMER_AUDIT', user_id);
    },
    initDatatable() {
      this.$refs.datatable.loadData();
    },
    sendVerificationEmail(customerId) {
      Swal.fire({
        title: this.$t("commons.are_you_sure"),
        html: `${this.$t("c_management.send_verification_email")}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("commons.approve"),
        cancelButtonText: this.$t("commons.cancel"),
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('customerManagement/resendVerificationEmail', customerId)
        }
      });
    }
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission('crm.users.index')) {
      this.$router.push('/no-permission')
    }
    this.datatableUrl = api_old.customer_datatable;
    this.datatableDataUrl = api_old.customer_datatable_data;
    const self = this;
    self.$nextTick(function () {
      $("#printExportItem").hide();
      $("#copyExportItem").hide();
      $("#excelExport").on("click", function (e) {
        e.preventDefault();
        let data = { url: `user/balanceexport`, label: `customers.xlsx` };
        self.$store.dispatch('global/GET_EXCEL', data);
      });
      $("#csvExportItem").hide();
      $("#pdfExportItem").hide();
    })
  },
}
</script>
<style lang="scss" scoped>
.truncateText {
  min-width: 150px;
  overflow: hidden;
  position: relative;
  margin: 0 5px 0 5px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
</style>
